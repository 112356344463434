<template>
  <section class="case-creation__step case-creation__patient-information">
    <div class="case-creation__card">
      <div class="case-creation__heading edit-view">
        <div class="title-with-icon">
          <span class="icon">
            <img
              src="@/assets/images/icons/icon-case-info.svg"
              alt="icon"
              class="img-fluid"
            />
          </span>
          <h3 class="title">Toxicology Orders Checklist</h3>
        </div>
      </div>

      <div class="case-creation__body">
        <div
          class="case-creation__toxicology-checklist"
          v-if="
            this.getCaseInformation &&
            this.getCaseInformation.toxicology_ereq_form
          "
        >
          <el-checkbox-group
            v-model="this.getCaseInformation.toxicology_ereq_form"
            disabled
          >
            <ul class="checkbox-order-view">
              <li
                class="search-checkbox"
                v-if="
                  this.getCaseInformation.toxicology_ereq_form.includes(
                    'Screen and Confirm Tests Selected Below'
                  )
                "
              >
                <el-checkbox label="Screen and Confirm Tests Selected Below" />
              </li>
              <li
                class="search-checkbox"
                v-if="
                  this.getCaseInformation.toxicology_ereq_form.includes(
                    'Screen and Confirm Boxes Checked Below'
                  )
                "
              >
                <el-checkbox label="Screen and Confirm Boxes Checked Below" />
              </li>
              <li
                class="search-checkbox"
                v-if="
                  this.getCaseInformation.toxicology_ereq_form.includes(
                    'Screen and Confirm All Negative and Positive Results'
                  )
                "
              >
                <el-checkbox
                  label="Screen and Confirm All Negative and Positive Results"
                />
              </li>
              <li
                class="search-checkbox"
                v-if="
                  this.getCaseInformation.toxicology_ereq_form.includes(
                    'Screen Only'
                  )
                "
              >
                <el-checkbox label="Screen Only" />
              </li>
              <li
                class="search-checkbox"
                v-if="
                  this.getCaseInformation.toxicology_ereq_form.includes(
                    'Confirm All Negative and Positive Results'
                  )
                "
              >
                <el-checkbox
                  label="Confirm All Negative and Positive Results"
                />
              </li>
              <li
                class="search-checkbox"
                v-if="
                  this.getCaseInformation.toxicology_ereq_form.includes(
                    'Confirm Only Boxes Checked Below'
                  )
                "
              >
                <el-checkbox label="Confirm Only Boxes Checked Below" />
              </li>
              <li
                class="search-checkbox"
                v-if="
                  this.getCaseInformation.toxicology_ereq_form.includes(
                    'Confirm Only Tests Selected Below'
                  )
                "
              >
                <el-checkbox label="Confirm Only Tests Selected Below" />
              </li>
              <li
                class="search-checkbox"
                v-if="
                  this.getCaseInformation.toxicology_ereq_form.includes(
                    'Screen Reflex to Confirmation'
                  )
                "
              >
                <el-checkbox label="Screen Reflex to Confirmation" />
              </li>
              <li
                class="search-checkbox"
                v-if="
                  this.getCaseInformation.toxicology_ereq_form.includes(
                    'Confirm All Prescribed Medications of Interest'
                  )
                "
              >
                <el-checkbox
                  label="Confirm All Prescribed Medications of Interest"
                />
              </li>
              <li
                class="search-checkbox"
                v-if="
                  this.getCaseInformation.toxicology_ereq_form.includes(
                    'Confirm Tests Selected Below'
                  )
                "
              >
                <el-checkbox label="Confirm Tests Selected Below" />
              </li>
            </ul>
          </el-checkbox-group>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("orders", ["getCaseInformation"]),
  },
};
</script>
